import React from 'react';
import { ClimbingBoxLoader } from 'react-spinners';
import './ViewSpin.css'

const ViewSpin = () => {
    return (
        <div className='view '>
        <div>
            <ClimbingBoxLoader color="#FF5733" />
        </div>
  <div className=''>
  <img className='imgMejarTwo' src='https://i.ibb.co/HxwLpg4/nexxxx.png' alt=''/>

  </div>
        </div>
    );
};

export default ViewSpin;