import React from 'react';
import './Navbar.css'

const NotFound = () => {
    return (
        <div className=''>
           
           <img src='https://i.ibb.co/QCTx4Bk/dhon.png' alt=''></img>
        </div>
    );
};

export default NotFound;